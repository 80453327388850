const isDefined = require('./is-defined')

/**
 * Format serial number to the proper length
 * @param {string} serialNumber - Serial number to format
 * @param {object} options - Options
 * @param {number} [options.digits=17] - Number of digits
 * @returns {string}
 */
const formatSerialNumber = (serialNumber, options) => {
  if (!isDefined(serialNumber)) {
    return null
  }

  const { digits = 17 } = {
    ...options,
  }

  const len = serialNumber.length
  const difference = digits - len

  if (difference <= 0) {
    return serialNumber
  }

  return `${Array.from({ length: difference }).fill(0).join('')}${serialNumber}`
}

module.exports = formatSerialNumber
