import React from 'react'
import * as PropTypes from 'prop-types'
import { Label } from './label'

export const Textarea = ({ id, label, name, placeholder, register, required, ...props }) => {
  return (
    <React.Fragment>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <textarea
        ref={register({ required })}
        name={name}
        placeholder={placeholder}
        required={required}
        id={id}
        {...props}
      />
    </React.Fragment>
  )
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
}

Textarea.defaultProps = {
  label: 'Message',
  name: 'Message',
  placeholder: null,
  required: false,
}
