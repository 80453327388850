import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/button/button'
import { FaRegCheckCircle } from '@react-icons/all-files/fa/FaRegCheckCircle'

const SuccessMessage = ({ message, messageBody, toggleModal }) => {
  return (
    <Container>
      <FaRegCheckCircle aria-hidden='true' focusable='false' role='presentation' />
      <h1>{message}</h1>
      <p>{messageBody}</p>
      {toggleModal ? (
        <StyledButton kind='div' onClick={toggleModal}>
          Close
        </StyledButton>
      ) : null}
    </Container>
  )
}

SuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
  messageBody: PropTypes.string.isRequired,
  toggleModal: PropTypes.func,
}

const Container = styled.div`
  text-align: center;

  svg {
    color: ${props => props.theme.color.g400};
    font-size: 60px;
  }

  h1 {
    font-size: 36px;
    margin: 0;
    padding: 10px 0 5px;
  }

  p {
    margin: 0;
    text-transform: none;
  }
`

const StyledButton = styled(Button)`
  margin: 20px auto 0;
  width: 200px;
`

export default SuccessMessage
