import React from 'react'
import * as PropTypes from 'prop-types'
import isValidPhone from 'utils/is-valid-phone'
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.us'
import { Label } from './label'
import { Controller } from 'react-hook-form'

export const Phone = ({
  control,
  defaultValue,
  id,
  label,
  name,
  placeholder,
  required,
  ...props
}) => {
  return (
    <React.Fragment>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <Controller
        control={control}
        name={name}
        rules={{ required, validate: value => isValidPhone(value) }}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value }) => (
          <Cleave
            type='tel'
            name={name}
            options={{ phone: true, phoneRegionCode: 'US' }}
            placeholder={placeholder}
            required={required}
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
          />
        )}
      />
    </React.Fragment>
  )
}

Phone.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool.isRequired,
}

Phone.defaultProps = {
  defaultValue: null,
  id: 'phone',
  label: 'Phone',
  name: 'Phone',
  placeholder: null,
  required: false,
}
