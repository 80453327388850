import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { NetlifyForm } from 'components/forms/netlify-form'
import ResponseMessage from 'components/forms/elements/response-message'
import SuccessMessage from 'components/forms/elements/success-message'
import { Email } from 'components/forms/elements/v2/email'
import { FieldError } from 'components/forms/elements/v2/field-error'
import { Input } from 'components/forms/elements/v2/input'
import { Phone } from 'components/forms/elements/v2/phone'
import { Submit } from 'components/forms/elements/v2/submit'
import { Textarea } from 'components/forms/elements/v2/textarea'
import { useForm } from 'react-hook-form'
import { useIsMounted } from 'hooks/use-is-mounted'
import { useLocation } from '@reach/router'
import Spacer from 'components/new/spacer'
import { Column, Grid } from 'components/grid'
import { fbq, fbqEvents, gtag, gtagEvents } from '@hutson/utils'

const formName = 'Used Equipment'

const SUBMIT_STATE = {
  loading: 'loading',
  success: 'success',
  error: 'error',
}

const UsedEquipmentForm = ({ equipmentLocation, equipmentPrice, stockNumber, url }) => {
  const {
    control,
    errors,
    formState: { isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm({ mode: 'onBlur' })

  const [submitState, setSubmitState] = useState(null)

  const isMounted = useIsMounted()

  const location = useLocation()

  const onSubmit = (data, event) => {
    event.preventDefault()

    setSubmitState(SUBMIT_STATE.loading)

    const formData = new FormData(event.target)

    if (typeof formData.get('Message') !== 'string') {
      formData.set('Message', '')
    }

    const pageUrl = formData.get('Page URL')

    const newPageUrl = new URL(pageUrl)

    if (!/hutsoninc\.com/gi.test(newPageUrl.hostname)) {
      console.log('Incorrect hostname in form `Page URL` field.')
      setSubmitState(SUBMIT_STATE.success)
      return
    }

    if (typeof pageUrl === 'string' && pageUrl.length > 255) {
      // Salesforce has a 255 character limit on the `Url` type. We're trimming the search and hash. These fields
      // aren't as important because the `RFQ Source` property on Requests are just so online sales can look at what
      // the customer saw.
      newPageUrl.hash = ''
      newPageUrl.search = ''

      const newPageUrlFinal = pageUrl.href

      if (newPageUrlFinal.length > 255) {
        const message = `${formData.get('Message')}\n${newPageUrlFinal}`

        formData.set('Page URL', '')
        formData.set('Message', message)
      } else {
        formData.set('Page URL', newPageUrlFinal)
      }
    }

    fetch('/', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (isMounted()) {
          setSubmitState(res.status === 200 ? SUBMIT_STATE.success : SUBMIT_STATE.error)
          fbq('track', fbqEvents.Lead, {
            currency: 'USD',
            value: equipmentPrice,
          })

          gtag('event', gtagEvents.generate_lead, {
            currency: 'USD',
            value: equipmentPrice,
            equipment_condition: 'used',
          })
        }
      })
      .catch(err => {
        if (isMounted()) {
          console.error('Error submitting form: ', err)
          setSubmitState(SUBMIT_STATE.error)
        }
      })
  }

  const onError = errors => {
    console.error(errors)

    if (isValid) {
      setSubmitState(SUBMIT_STATE.error)
    }
  }
  return (
    <React.Fragment>
      {submitState !== SUBMIT_STATE.success ? (
        <NetlifyForm
          name={formName}
          onSubmit={handleSubmit(onSubmit, onError)}
          id='used-equipment-form'
        >
          <Grid>
            <Column columns='1/2'>
              <Input
                register={register}
                label='First Name'
                name='First Name'
                id='used-equipment-form-first-name'
                required
              />
              {errors['First Name'] ? <FieldError type={errors['First Name'].type} /> : null}
            </Column>
            <Column columns='1/2'>
              <Input
                register={register}
                label='Last Name'
                name='Last Name'
                id='used-equipment-form-last-name'
                required
              />
              {errors['Last Name'] ? <FieldError type={errors['Last Name'].type} /> : null}
            </Column>
          </Grid>
          <Grid>
            <Column columns='2/3'>
              <Email register={register} id='used-equipment-form-email' required />
              {errors['Email'] ? <FieldError type={errors['Email'].type} /> : null}
            </Column>
            <Column columns='1/3'>
              <Phone control={control} id='used-equipment-form-phone' required />
              {errors['Phone'] ? <FieldError type={errors['Phone'].type} /> : null}
            </Column>
          </Grid>
          <Textarea
            register={register}
            label='Message'
            name='Message'
            id='used-equipment-form-message'
          />
          {errors['Message'] ? <FieldError type={errors['Message'].type} /> : null}
          <div style={{ display: 'none' }}>
            <label htmlFor='used-equipment-form-stock-number'>Stock Number</label>
            <input
              ref={register()}
              type='text'
              name='Stock Number'
              id='used-equipment-form-stock-number'
              value={stockNumber}
              readOnly
            />
            <label htmlFor='used-equipment-form-equipment-location'>Equipment Location</label>
            <input
              ref={register()}
              type='text'
              name='Equipment Location'
              id='used-equipment-form-equipment-location'
              value={equipmentLocation}
              readOnly
            />
            <label htmlFor='used-equipment-form-equipment-price'>Equipment Price</label>
            <input
              ref={register()}
              type='text'
              name='Equipment Price'
              id='used-equipment-form-equipment-price'
              value={equipmentPrice}
              readOnly
            />
            <label htmlFor='used-equipment-form-page-url'>Page URL</label>
            <input
              ref={register()}
              type='text'
              name='Page URL'
              id='used-equipment-form-page-url'
              value={location.href}
              readOnly
            />
          </div>
          <Spacer size='m' variable />
          <Submit
            className='submit-used-equipment-RFQ'
            loading={isSubmitting || submitState === SUBMIT_STATE.loading}
          />
        </NetlifyForm>
      ) : null}
      {submitState === SUBMIT_STATE.success && (
        <SuccessMessage
          message='Success!'
          messageBody='Your form has been submitted. Our team will be in touch with you soon!'
        />
      )}
      {submitState === SUBMIT_STATE.error && (
        <ResponseMessage
          error={true}
          message='Error occurred.'
          messageBody={
            <React.Fragment>
              There was an issue submitting your form. Please try again. If problems persist, please
              contact <a href='mailto:contactus@hutsoninc.com'>our support team</a>.
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  )
}

UsedEquipmentForm.propTypes = {
  stockNumber: PropTypes.string.isRequired,
  equipmentPrice: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default UsedEquipmentForm
