import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Form from 'components/forms/used-equipment'
import { Helmet } from 'react-helmet'
import { organizationSchema } from 'data/schema-data'
import Layout from 'components/layout'
import Content from 'components/content'
import H2 from 'components/typography/h2'
import Button from 'components/button/button'
import SimilarProducts from 'components/similar-products'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import ImageGallery from 'react-image-gallery'
import ImageGalleryStyle from 'components/image-gallery/style.js'
import SocialShare from 'components/social/social-share'
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft'
import { FaPrint } from '@react-icons/all-files/fa/FaPrint'
import Breadcrumbs from 'components/breadcrumbs'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import UnstyledButton from 'components/button/unstyled-button'
import { gtag, gtagEvents, isBrowser } from '@hutson/utils'
import scrollTo from 'utils/scroll-to'
import toHandle from 'utils/to-handle'
import formatSerialNumber from 'utils/format-serial-number'
import formatUsedEquipmentMeterType from 'utils/format-used-equipment-meter-type'
import getUsedEquipmentImageSrc from 'utils/get-used-equipment-image-src'
import isDefined from 'utils/is-defined'
import Disclaimer from 'components/landing-page/disclaimer'
import Spacer from 'components/new/spacer'
import todayIsBetweenDates from 'utils/dates/today-is-between-dates'

const HUT229_START_DATE = new Date('2024-09-11T00:00:00-06:00')
const HUT229_END_DATE = new Date('2024-10-04T00:00:00-06:00')
const HUT229_DISCLAIMER =
  'Offer valid September 11 through October 4, 2024. For agricultural use only. Subject to approval by John Deere Financial. Residency restrictions, terms, and conditions may apply. See dealer for details. Program ID: HUT229-2.'

const goBack = () => {
  window.history.back()
}

const handlePrint = () => {
  if (isBrowser) {
    // TODO: Add parameters
    gtag('event', gtagEvents.print_page)
    window.print()
  }
}

const UsedEquipmentSinglePage = props => {
  const {
    data: {
      machineFinderEquipment: {
        canonicalSlug,
        category: { title: category },
        horsepower,
        id,
        images,
        initialPrice,
        hut229Details,
        inHUT229,
        isNew,
        location,
        manufacturer,
        meters,
        model,
        // modelYear,
        onLot,
        options,
        price,
        reducedPrice,
        slug,
        sourceLink,
        stockNumber,
        subcategory: { title: subcategory },
        title,
      },
      machineFinderEquipment,
      allMachineFinderEquipment,
      site: { siteMetadata },
    },
  } = props

  const hut229ShowingOption = inHUT229
    ? hut229Details.reduce((currentBestOption, option) => {
        if (option.payment < currentBestOption.payment) {
          return option
        }
        return currentBestOption
      })
    : null
  const hut229Active =
    inHUT229 &&
    hut229ShowingOption &&
    todayIsBetweenDates({ start: HUT229_START_DATE, end: HUT229_END_DATE })

  // Format serial number
  const serialNumber = formatSerialNumber(machineFinderEquipment.serialNumber)

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Used Equipment',
      link: '/used-equipment/',
    },
    {
      name: category,
      link: `/used-equipment/${toHandle(category)}/`,
    },
    {
      name: subcategory,
      link: `/used-equipment/${toHandle(category)}/${toHandle(subcategory)}/`,
    },
    {
      name: manufacturer,
      link: `/used-equipment/${toHandle(category)}/${toHandle(subcategory)}/${toHandle(
        manufacturer
      )}/`,
    },
    {
      name: model,
      link: canonicalSlug,
    },
    {
      name: String(stockNumber),
      link: slug,
    },
  ]

  const meta = {
    title: `${isNew ? '' : 'Used '}${title} for sale - #${stockNumber} | Hutson Inc`,
    description: `${isNew ? '' : 'Used '}${title} Stock #${stockNumber} for sale at Hutson in ${
      location.city
    }, ${location.state}. We have a large selection of used ${
      /uncategorized/i.test(subcategory) ? 'equipment' : subcategory
    } in stock.`,
    image: getUsedEquipmentImageSrc(images[0], { relativeFallback: false }),
    pageUrl: `${siteMetadata.siteUrl}${slug}`,
    priceValidUntil: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toISOString(), // Today + 90 days
    availability: onLot
      ? { text: 'in stock', schema: 'https://schema.org/InStock' }
      : { text: 'pending', schema: 'https://schema.org/LimitedAvailability' },
  }

  // Format equipment details
  const additionalDetails = []

  const details = options.reduce((acc, option) => {
    const { label, value } = option

    // Check if detail label is already in the list
    const matchIndex = acc.findIndex(obj => obj.label === label)

    if (matchIndex !== -1) {
      // Add to detail item if match is found
      acc[matchIndex].value.push(value)
    } else if (isDefined(label)) {
      // Create new item
      acc.push({
        label,
        value: [value],
      })
    } else {
      // Add to additional details if option is not labeled
      additionalDetails.push(value)
    }

    return acc
  }, [])

  // Add additional details
  if (additionalDetails.length > 0) {
    details.push({
      label: 'Additional Information',
      value: additionalDetails,
    })
  }

  // Format gallery images
  const galleryImages = images.map((image, index) => {
    const regularImageSrc = getUsedEquipmentImageSrc(
      { regular: image.regular },
      { relativeFallback: true }
    )
    const largeImageSrc = getUsedEquipmentImageSrc(
      { large: image.large },
      { relativeFallback: true }
    )
    return {
      original: regularImageSrc,
      thumbnail: regularImageSrc,
      originalAlt: `Photo of ${title}`,
      thumbnailAlt: `Photo ${index + 1} of ${images.length}`,
      imageSet: [
        {
          srcSet: regularImageSrc,
          media: '(max-width: 799px)',
        },
        {
          srcSet: largeImageSrc,
          media: '(min-width: 800px)',
        },
      ],
    }
  })

  const similar = fetchSimilarProducts(id, allMachineFinderEquipment.nodes).map(obj => ({
    id: obj.id,
    image: getUsedEquipmentImageSrc(obj.primaryImage, { relativeFallback: true }),
    imageAlt: obj.title,
    link: obj.slug,
    price: obj.price,
    wasPrice: obj.price < obj.initialPrice ? obj.initialPrice : null,
    subtitle: `${category}/${subcategory}`,
    title: obj.title,
  }))

  useEffect(() => {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'product-price': price,
      })
    }
  }, [id, price])

  const locationName = `${location.city}, ${location.state}`
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta property='og:title' content={meta.title} />
        <meta name='description' content={meta.description} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:url' content={meta.pageUrl} />
        <meta property='og:image' content={meta.image} />
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <link rel='canonical' href={canonicalSlug} />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            'description': meta.description,
            'url': meta.pageUrl,
            'name': title,
            'image': meta.image,
            ...(isDefined(stockNumber) ? { sku: stockNumber } : {}),
            ...(isDefined(serialNumber) ? { mpn: serialNumber } : {}),
            'category': `Used Equipment/${category}/${subcategory}`,
            'offers': {
              '@type': 'Offer',
              'priceCurrency': 'USD',
              'price': price,
              'priceValidUntil': meta.priceValidUntil,
              'itemCondition': `https://schema.org/${isNew ? 'New' : 'Used'}Condition`,
              'availability': meta.availability.schema,
              'seller': organizationSchema,
              'url': meta.pageUrl,
            },
            'additionalProperty': [
              {
                '@context': 'http://schema.org/',
                '@type': 'PropertyValue',
                'name': 'Equipment Location',
                'value': locationName,
              },
            ],
            'sameAs': sourceLink,
            ...(manufacturer && {
              brand: {
                '@context': 'http://schema.org/',
                '@type': 'Organization',
                'name': manufacturer,
              },
              manufacturer: {
                '@context': 'http://schema.org/',
                '@type': 'Organization',
                'name': manufacturer,
              },
            }),
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <StyledContent>
        <BreadcrumbsContainer>
          <BackButton aria-label='Go back to previous page' onClick={goBack}>
            <FaArrowLeft aria-hidden='true' focusable='false' role='presentation' />{' '}
            <span aria-hidden='true'>Back</span>
          </BackButton>
          <StyledBreadcrumbs breadcrumbs={breadcrumbs} />
        </BreadcrumbsContainer>
        <MainContent>
          <GalleryContainer>
            <ImageGallery
              items={galleryImages}
              infinite={false}
              showFullscreenButton={true}
              showPlayButton={false}
              showBullets={false}
              showIndex={true}
              onThumbnailClick={(e, i) => {
                e.preventDefault()
              }}
            />
          </GalleryContainer>
          <DetailsContainer>
            <Title>{title}</Title>
            <EquipmentIdsContainer>
              {isDefined(stockNumber) ? (
                <div>
                  <Semibold>Stock #</Semibold>
                  {stockNumber}
                </div>
              ) : null}
              {isDefined(serialNumber) ? (
                <div>
                  <Semibold>Serial #</Semibold>
                  {serialNumber}
                </div>
              ) : null}
            </EquipmentIdsContainer>
            <OverviewList>
              {isNew ? (
                <li>
                  <Semibold>Condition:</Semibold> New
                </li>
              ) : null}
              {isDefined(horsepower) ? (
                <li>
                  <Semibold>Engine HP:</Semibold> {horsepower}
                </li>
              ) : null}
              {Array.isArray(meters)
                ? meters.map(meter => (
                    <li key={meter.type}>
                      <Semibold>
                        {formatUsedEquipmentMeterType(meter.type, { titleCase: true })}:
                      </Semibold>{' '}
                      {meter.value.toLocaleString('en-US')}
                    </li>
                  ))
                : null}
              <li>
                <Semibold>Location:</Semibold> <Link to={location.slug}>{locationName}</Link>
              </li>
            </OverviewList>
            <Hr />
            <div>
              <Price reducedPrice={reducedPrice}>
                {price.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                })}
              </Price>
              {reducedPrice && (
                <WasPrice>
                  {initialPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                  })}
                </WasPrice>
              )}
              {hut229Active ? (
                <HUT299Section>
                  <Spacer size='xs' />
                  or{' '}
                  <LeasePrice>
                    {hut229ShowingOption.payment.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                    })}
                  </LeasePrice>
                  /year
                  <br />
                  {hut229ShowingOption.term} year/{hut229ShowingOption.hours} hour lease option
                  <br />
                  <Link to='/lp/5dc6bde0-87cb-4156-96f9-d1ca3782b46d/'>See program details</Link>
                </HUT299Section>
              ) : null}
            </div>
            <Button as={QuoteButton} href='#contact' onClick={scrollTo}>
              Talk to sales
            </Button>
            <Resources>
              <a href='tel:866-644-0949'>Call us: 866-644-0949</a>
            </Resources>
            <Hr />
            <PrintButtonContainer>
              <PrintButton onClick={handlePrint}>
                <FaPrint aria-hidden='true' focusable='false' role='presentation' /> Print/Download
                Page
              </PrintButton>
            </PrintButtonContainer>
            <div className='social-icons'>
              <SocialShare title={title} label={true} print={false} />
            </div>
          </DetailsContainer>
        </MainContent>

        {details.length > 0 && (
          <Section>
            <DetailsTable>
              <DetailsTableHead>
                <tr>
                  <td colSpan='2'>{title} Details</td>
                </tr>
              </DetailsTableHead>
              <DetailsTableBody>
                {details.map((detail, key) => (
                  <tr key={key}>
                    <td>{detail.label}</td>
                    <td>
                      {detail.value.length === 1 ? (
                        detail.value
                      ) : (
                        <ul>
                          {detail.value.map((v, i) => (
                            <li key={i}>{v}</li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                ))}
              </DetailsTableBody>
            </DetailsTable>
          </Section>
        )}

        <Section id='contact'>
          <H2>Questions? Send us a message:</H2>
          <Form
            stockNumber={String(stockNumber)}
            url={slug}
            equipmentPrice={String(price)}
            equipmentLocation={location.title}
            equipmentName={title}
            equipmentCategory={category}
            equipmentSubcategory={subcategory}
          />
        </Section>
        {hut229Active ? (
          <React.Fragment>
            <Spacer size='xl' />
            <Disclaimer>{HUT229_DISCLAIMER}</Disclaimer>
          </React.Fragment>
        ) : null}
      </StyledContent>

      {similar && similar.length > 0 && (
        <SimilarContainer>
          <Content>
            <H2>Similar Equipment</H2>
            <SimilarProducts similarProducts={similar} />
          </Content>
        </SimilarContainer>
      )}

      <ImageGalleryStyle />
    </Layout>
  )
}

const Section = styled.div`
  margin: 40px 0 0;

  @media (min-width: 800px) {
    margin: 80px 0 0;
  }
`

const BreadcrumbsContainer = styled.div`
  margin-bottom: 20px;
`

const BackButton = styled(UnstyledButton)`
  background-color: #efefef;
  color: #7d7d7d;
  display: inline-block;
  font-size: 1.1em;
  padding: 10px 15px;

  svg {
    color: #7d7d7d;
    font-size: 0.8em;
    margin: -3px 2px 0 0;
    vertical-align: middle;
  }

  .breadcrumbs-list {
    display: none;
  }

  :hover,
  :focus {
    background-color: #dfdfdf;
  }

  @media (min-width: 800px) {
    cursor: pointer;
    font-size: 1em;
    margin: 0 15px 0 0;
    padding: 5px 10px;

    svg {
      font-size: 0.7em;
      margin: -2px 1px 0 0;
    }
  }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: none;

  @media (min-width: 800px) {
    display: inline-block;
    margin-bottom: 0;
  }
`

const MainContent = styled.div`
  @media (min-width: 800px) {
    ${clearfix}
  }
`

const Hr = styled.hr`
  background-color: #d7d7d7;
  border: none;
  color: #efefef;
  height: 1px;
  margin: 20px auto;
  width: 100%;
`

const GalleryContainer = styled.div`
  @media (min-width: 800px) {
    ${column('3/5')}
  }
`

const DetailsContainer = styled.div`
  margin-top: 30px;

  @media (min-width: 800px) {
    ${column('2/5')}
    margin-top: 0;
  }
`

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  padding: 0;
`

const EquipmentIdsContainer = styled.div`
  margin-top: 5px;

  > div {
    display: inline-block;

    :first-child {
      margin-right: 20px;
    }
  }
`

const Semibold = styled.span`
  font-weight: 600;
`

const OverviewList = styled.ul`
  font-size: 1.1em;
  margin: 10px auto 15px;

  li {
    margin: 0;
  }

  a {
    color: #377539;
    text-decoration: underline;
  }

  @media (min-width: 800px) {
    font-size: 1em;
  }
`

const Price = styled.span`
  color: ${props => (props.reducedPrice ? props.theme.color.r300 : '#000')};
  display: inline-block;
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
`

const WasPrice = styled.span`
  color: #7d7d7d;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1;
  text-decoration: line-through;
`

const HUT299Section = styled.div`
  line-height: 1.4;

  a {
    color: #377539;
    text-decoration: underline;

    :hover,
    :active,
    :focus {
      text-decoration: none;
    }
  }
`

const LeasePrice = styled.span`
  color: #000;
  display: inline-block;
  font-size: 1.75em;
  font-weight: bold;
  line-height: 1.4;
`

const QuoteButton = styled.a`
  display: block;
  margin: 15px 0;
  max-width: 250px;
  text-decoration: none;
`

const Resources = styled.div`
  font-size: 1.1em;
  font-weight: 600;

  a {
    color: #377539;
    text-decoration: underline;
  }
`

const PrintButtonContainer = styled.div`
  margin-bottom: 15px;
`

const PrintButton = styled(UnstyledButton)`
  background-color: #efefef;
  color: #7d7d7d;
  cursor: pointer;
  display: inline-block;
  padding: 10px;

  :hover,
  :focus {
    background-color: #dfdfdf;
  }

  svg {
    color: #7d7d7d;
    font-size: 1em;
    margin-right: 5px;
  }
`

const DetailsTable = styled.table`
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`

const DetailsTableHead = styled.thead`
  color: #fff;
  background-color: #000;
  font-size: 1.35em;
  font-weight: bold;
  margin: 0;
  text-transform: none;
  vertical-align: middle;

  td {
    padding: 15px;
  }

  @media (min-width: 800px) {
    display: table-header-group;
  }
`

const DetailsTableBody = styled.tbody`
  tr {
    border-bottom: 1px solid #d7d7d7;
    display: block;
    padding: 15px;

    :nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  td {
    padding: 0;
    display: block;

    :first-child {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  li {
    margin-bottom: 3px;
  }

  @media (min-width: 800px) {
    tr {
      display: table-row;
      padding: auto;
    }

    td {
      display: table-cell;
      margin: auto;
      padding: 15px;

      :first-child {
        border-right: 1px solid #d7d7d7;
        width: 200px;
      }
    }
  }
`

const SimilarContainer = styled.div`
  background-color: #f2f0f0;
`

const StyledContent = styled(Content)`
  @media print {
    ${BreadcrumbsContainer},
    ${QuoteButton},
        ${PrintButtonContainer},
        ${SimilarContainer},
        .social-icons
        #contact {
      display: none;
    }
  }
`

const fetchSimilarProducts = (id, all) => {
  let index = all.findIndex(obj => obj.id === id)
  let similar = []
  similar.push(...all.slice(index + 1))
  similar.push(...all.slice(0, index))
  return similar.slice(0, 3)
}

export const pageQuery = graphql`
  query usedEquipmentTemplate($id: String!, $category: String!, $subcategory: String!) {
    machineFinderEquipment(id: { eq: $id }) {
      canonicalSlug
      category {
        title
      }
      horsepower
      id
      images {
        large
        regular
      }
      initialPrice
      isNew
      hut229Details {
        term
        hours
        payment
      }
      inHUT229
      location {
        city
        name
        slug
        state
        title
      }
      manufacturer
      meters {
        type
        value
      }
      model
      modelYear
      onLot
      options {
        label
        value
      }
      price
      reducedPrice
      serialNumber
      slug
      sourceLink
      stockNumber
      subcategory {
        title
      }
      title
    }
    allMachineFinderEquipment(
      filter: {
        category: { title: { eq: $category } }
        subcategory: { title: { eq: $subcategory } }
      }
      sort: { fields: [modifiedDate], order: DESC }
    ) {
      nodes {
        id
        initialPrice
        price
        primaryImage {
          large
          regular
        }
        slug
        title
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default UsedEquipmentSinglePage
