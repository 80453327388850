import React from 'react'
import noop from 'utils/noop'

const NetlifyForm = props => {
  const {
    action,
    children,
    honeypotName = '__bf',
    honeypotInputProps = {},
    name,
    nameInputProps = {},
    onError = noop,
    onFailure = noop,
    onSuccess = noop,
    onSubmit,
    ...additionalProps
  } = props

  const defaultOnSubmit = event => {
    event.preventDefault()

    return fetch('/', {
      method: 'POST',
      body: new FormData(event.target),
    })
      .then(res => {
        if (res.status === 200) {
          onSuccess(res)
        } else {
          onFailure(res)
        }
      })
      .catch(err => {
        onError(err)
      })
  }

  const formDataAttributes = {
    'data-netlify': 'true',
  }

  if (honeypotName) {
    formDataAttributes['netlify-honeypot'] = honeypotName
  }
  return (
    <form
      action={action}
      method='POST'
      name={name}
      onSubmit={onSubmit ? onSubmit : defaultOnSubmit}
      {...formDataAttributes}
      {...additionalProps}
    >
      {children}
      <div style={{ display: 'none' }}>
        <input type='hidden' name='form-name' value={name} readOnly {...nameInputProps} />
        {honeypotName ? <input type='text' name={honeypotName} {...honeypotInputProps} /> : null}
      </div>
    </form>
  )
}

export default NetlifyForm
